@media (max-width: 575px) {
  .inner-container {
    width: 100%;
  }
  .header__close img {
    height: 8.5vw;
  }
  /* .overlay-match{
    width: 121px !important;
  } */
/* 
  .sport-accord-body .ps-1{
 
    font-size: 13.44px;

} */
  .game-grid {
    padding-left: 300px !important;
    padding-right: 10px;
  }
  .lobby {
    width: 100%;
  }
  /* .lobby .game-grid {
    padding-left: 240px !important;
  } */
  .match-odd-table li h2 {
    font-size: 0.9285714285714286rem !important;
    margin: 0;
    font-weight: 700;
    width: 85%;
    padding-right: 7px;
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
  .fancy-list-first {
    width: 100% !important;
    text-align: left !important;
    gap: 0 !important;
    align-items: center;
  }

  .fancy-list-first h2 {
    font-size: 0.9285714285714286rem !important;
    margin: 0;
    font-weight: 700;
    padding-right: 7px;
  }

  .fancy-list-first svg {
    font-weight: 600;
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin-right: 10px;
  }

  .d-flex.back-lay-head.justify-content-end.ms-auto.pb-1 {
    line-height: 14px;
    width: 121px;
    font-size: 9.6px;
  }

  .fav .overlay-match {
    width: 102 !important;
  }
  .sports-team-table .back-lay-head {
    padding: 4px 0px !important;
    border-radius: 8px 8px 0px 0px;
  }

  .sport-accord-body .ps-4 {
    font-size: 13.44px;
  }
  .header {
    position: fixed !important;
  }
  /* .sports-team-table .back-lay-head .half-table {
    flex: 0 0 220px !important;

  } */
  .sport-header-inner {
    width: 70px !important;
  }
  /* .match-odd-table li > div > div {
    width: 59.282px !important ;
    padding-top: 0 !important;
    font-size: 1.0714285714285714rem;
  }
 */

  
  .tab-indicator-new {
    position: absolute;
    /* width: 60px !important; */
    /* height: 55px; */
    top: 10%;
  }

  .sports-team-table .back-lay-head h4 {
    font-size: 13px;
  }
  .sports-team-table .back-lay-head .half-table span {
    margin: 0px 4px;
  }
  .master-pass {
    font-size: 0.9285714285714286rem;
  }
  .closed-bet-slip .master-pass {
    font-size: 12px;
  }
  .market-depth-body li {
    padding: 10px 14px;
  }
  .fancy-sport-header button {
    font-size: 14px;
  }
  .bet-notification > div {
    max-width: 70px;
    padding-left: 10px;
  }
  .total-stack button {
    flex: 0 0 157px;
    max-width: 157px;
    font-size: 14px;
  }
  .form-stake .form-control {
    padding: 5px 6px;
  }
  .form-stake span {
    font-size: 13px;
    padding-left: 5px;
  }
  .form-stake h4 strong {
    font-size: 15px;
  }
  .accept-odds .form-check,
  .form-stake h4 {
    font-size: 14px;
  }
  .accept-odds span {
    font-size: 12px;
  }
  .total-stack h5 {
    font-size: 16px;
  }
  .total-stack h6 {
    font-size: 13px;
  }
  .wallet-form-control input.form-control {
    padding: 11px 12px;
    font-size: 15px;
  }
  .closed-bet-slip li strong {
    font-size: 14px;
  }
  .setting-panel .form-check input[type="checkbox"] {
    font-size: 24px;
  }
  .mobile-height-layout {
    height: auto;
  }
  button.theme-btn {
    padding: 6px 29px;
    font-size: 20px;
  }
  .current-bets-table .odds-table th {
    padding-left: 10px;
    font-size: 0.9285714285714286rem;
  }
  .p2transfer-form .form-label {
    font-size: 16px;
  }
  .next-icon img {
    max-width: 19px;
  }
  .current-bets-table table tbody .lay-back td {
    font-size: 0.9285714285714286rem;
  }
  .current-bets-table table tbody td {
    font-size: 0.7142857142857143rem;
  }
  .current-bets-table table tbody td strong {
    font-size: 14px;
  }
  .capital-inner span {
    font-size: 13px;
  }
  .search-games-sec {
    top: 145px;
    padding-top: 3px;
  }
  .order-checkbox .form-check {
    font-size: 14px;
  }
  .current-bets-table .lay-back td {
    padding: 12px 9px;
    font-weight: 700;
  }
  .active-log-table table th {
    font-size: 14px;
  }
  .active-log-table tr td {
    font-size: 14px;
  }
  .select-container.bet-history-dropdown .dropdown-item {
    padding-left: 85px;
  }
  .active-log-table tr td {
    font-size: 15px;
  }
  .show-hide-bet {
    padding: 2px 40px 0px;
  }
  /* .bet-name {
    padding: 7px 8px;
  } */
  .bets-table thead th {
    font-size: .8571428571428571rem;
    font-weight: 500;
}
  .main-casino-wrapper .games-card-inner:first-child {
    padding-top: 0px;
  }
  .search-games-sec .form-control {
    padding-bottom: 8px;
  }
  .current-bets-table .master-pass::before {
    display: none;
    padding-left: 0px;
  }
  .current-bets-table .master-pass {
    padding-left: 0;
    margin-left: 0px;
  }
  .bottom-navigation ul li {
    padding: 2px 15px;
    font-size: 9px;
  }
  .bottom-navigation div {
    max-width: 31px;
  }
  .games-slot .slot1 {
    padding: 3px 0;
  }
  .games-slot .slot1 img {
    max-width: 38px;
    margin: 0 auto;
  }
  .games-slot span {
    font-size: 14px;
  }
  .header .social-header a {
    padding: 0.75rem 0.859rem !important;
    margin-right: 7px;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    font-weight: 700;
  }
  .games-wrapper .card {
    padding: 10px 16px;
    /* border-radius: 15px; */
    margin-bottom: 7px;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06);
  }
  .games-wrapper .card strong {
    font-size: 28px;
  }
  .games-wrapper .card span {
    font-size: 17px;
  }
  .leftpanel {
    display: none;
  }
  .main-outer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidebar-wrapper {
    width: 75%;
  }
  .sidebar-wrapper ul a {
    font-size: 1rem;
    background-position: right 11px top 17px;
    background-size: 20px;
  }
  .logout-button {
    font-size: 17px;
  }
  .time-zone {
    font-size: 15px;
  }
  .without-login {
    padding-top: 45px !important;
  }
  .with-login {
    padding-top: 65px !important;
  }
  /* .casino-category img {
    max-width: 41px;
  } */
  .casinofilter span {
    padding: 2px 10px;
    margin-right: 15px;
    font-size: 13px;
  }
  .subcasino span {
    padding: 4px 11px;
    margin-right: 7px;
    font-size: 14px;
  }
  .parlay-checkbox .form-check {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding-left: 0;
  }
  .bet-notification {
    left: 0px;
    bottom: 76px;
  }
  .by-time-sec .capital {
    position: relative;
  }
  .by-time-sec .in-play {
    padding: 5px 14px;
  }
  /* .capital button {
        position: absolute;
        right: 0;
        bottom: 0;
    } */
  .capital-inner {
    flex: 0 0 63%;
    max-width: 63%;
  }

  .parlay-checkbox {
    background: #1b1f23;
    padding: 0.6rem 0.5rem;
    margin-right: 9px;
  }
  .sports-heading {
    font-size: 1.0714285714rem;
  }
  .game-menu ul {
    overflow-x: auto;
  }
  /* .match-odd-table.bg-white.p-3.px-0.py-1 {
        width: 414px;
    } */
  .match-odd-table.mobile-match {
    width: 100% !important;
  }
  .sports-team-table {
    overflow-x: auto;
  }
  .game-menu ul::-webkit-scrollbar-thumb,
  .game-menu ul,
  ::-webkit-scrollbar-track {
    background: #333333;
  }

  .game-menu ul li svg {
    font-size: 1.2142857143rem;
    margin-right: 5px;
  }
  .game-menu ul li {
    padding: 5.59px 9px;
    font-size: 0.9285714286rem;
    height: 2.1428571429rem !important;
  }
  .game-menu ul li div {
    display: flex;
  }

  .parlay-inner-tab {
    width: 100%;
    justify-content: space-between;
    /* margin-top: 6px; */
  }
  .game-menu {
    padding: 4px 5px;
  }
  .parlay-checkbox .form-check .form-check-input {
    margin-left: 0px;
  }
  .parlay-checkbox .form-check-label {
    font-size: 15px;
    display: none;
  }
  .parlay-icons {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-right: 15px;
  }
  .parlay-inner-tab li:last-child {
    margin: 0;
  }
  .casinofilter {
    padding: 3px 7px;
  }
  .casino-all-games .games-card-inner h2 {
    /* padding: 10px 34px 10px 15px; */
    font-size: 1rem;
    margin-left: -19px;
}
  .parlay-icons svg {
    font-size: 26px;
    margin-right: 7px;
  }
  .download-apk img {
    max-width: 8.5714285714rem;
    height: 41.27px !important;
  }
  .parlay-inner-tab li {
    font-size: 1.0714285714285714rem;
    margin-right: 10px;
  }
  .match-list ul li {
    padding: 15px 26px 15px 12px;
    font-size: 1.0714285714285714rem;
  }
  /* .login-log-sec {
       position: initial;
    } */
  .login-panel main {
    padding-top: 0px;
  }
  .games-inner aside li {
    padding: 7px 10px;
  }
  main.showbottomnavigation {
    height: calc(100vh - 71px);
  }
  .bottom-navigation {
    position: fixed;
  }
  .sports-widget {
    position: fixed;
  }
  .market-depth-modal {
    min-height: auto;
    /* min-height: auto; */
    min-height: calc(100% - 5.85714rem);
    transition: all 0.3s ease-out;
  }

  .slide-up {
    /* Start the div below its normal position */
    transform: translateY(100%);
    /* Apply the animation */
    animation: slideUp 0.5s ease-in-out forwards;
  }

  /* Define the keyframes for the animation */
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* Apply this to the div you want to animate */
  .slide-left {
    /* Start the div off the screen to the right */
    transform: translateX(100%);
    /* Apply the animation */
    animation: slideLeft 2s ease-in-out forwards;
  }

  /* Define the keyframes for the animation */
  @keyframes slideLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .market-depth-modal-announcement {
    min-height: 100% !important;
  }
  .info-rule {
    padding-left: 31px;
  }
  .info-rule li {
    font-size: 15px;
    margin-bottom: 14px;
    padding: 0px 13px 0px 4px;
  }
  .info-detail .title h4 {
    font-size: 17px;
  }
  .sport-rules-sec li {
    font-size: 17px;
  }
  .sport-rules-sec li button {
    font-size: 18px;
  }
  .select-container .dropdown-menu a {
    font-size: 15px;
    padding: 4px 12px;
  }
  /* .sport-listing .accordion-button {
    padding: 10px 17px 10px 0px;
  } */
  .sports-widget ul {
    padding: 4px 9px;
  }
  .live-score {
    font-size: 17px;
  }
  .team-score span {
    font-size: 16px;
  }
  .sports-tab-panel h3.active::after {
    width: 90%;
  }
  .sports-listing-score .team-wise-score {
    padding: 3px 7px;
  }
  .sports-listing-score .team-wise-score strong {
    font-size: 1.0714285714285714rem;
  }
  .balance-sec {
    padding: 9px 10px;
    border-radius: 8px;
  }
  .balance-sec h5 {
    font-size: 17px;
  }
  .sports-tab-panel h3,
  .order-checkbox label.text-yellow {
    font-size: 15px;
  }
  .main-casino-wrapper .games-card-inner {
    padding: 10px 18px;
    /* margin: 0px -10px; */
    border-radius: 0px;
  }
  .capital .counter {
    text-align: center;
    font-size: 0.9285714286rem;
  }
  .in-play-green {
    font-size: 11px;
  }
  /* .sport-listing .accordion-button {
    font-size: 14px;
  } */

}
@media (max-width: 380px) {
  .games-wrapper .game-card {
    width: calc(33.33% - 6px);
    margin: 4px 3px;
  }
  .overlay-match{
    width:102px !important;
  }
  .game-grid {
    padding-left: 300px !important;
    padding-right: 10px;
  }
  .header__close img {
    height: 7.5vw;
  }

  .lobby {
    width: 100%;
  }
  .fav .overlay-match {
    width: 102 !important;
  }
  .match-odd-table li {
    padding: 0px 0px 0px 8px !important;
  }
  .match-odd-table li h2 {
    font-size: 0.9285714285714286rem !important;
    margin: 0;
    font-weight: 700;
    padding-right: 10px;
  }
  .d-flex.back-lay-head.justify-content-end.ms-auto.pb-1 {
    line-height: 14px;
    width: 104px;
    font-size: 9.6px;
  }
  .sport-accord-body .ps-4 {
    font-size: 13.44px;
  }
  .sports-team-table .back-lay-head {
    padding: 4px 0px !important;
    border-radius: 8px 8px 0px 0px;
  }

  /* .sports-team-table .back-lay-head .half-table {
    flex: 0 0 220px !important;

  } */
  .sport-header-inner {
    width: 70px !important;
  }

  .match-odd-table li > div > div {
    width: 50px !important;
    padding-top: 0 !important;
  }

  .tab-indicator-new {
    position: absolute;
    /* width: 60px !important;
    height: 55px !important; */
    top: 10%;
  }

  /* .leading-normal {
    padding: 2px 8px;
  } */
  .leading-normal div {
    font-size: 11px !important;
  }

  .in-play-green {
    font-size: 11px;
  }

  .games-inner aside li span {
    font-size: 0.6428571428571429rem;
  }

  .counter {
    font-size: 0.9285714286rem;
    white-space: nowrap;
  }
  .sports-team-table li > div > div {
    /* padding: 4px 6px; */
    /* font-size: 13px; */
    /* width: 64px; */
  }
  .sports-team-table .back-lay-head .half-table span {
    margin: 0px 6px;
    font-size: 11px;
  }
  .sports-team-table .overlay-match {
    width: 31%;
  }
  .sports-team-table .back-lay-head h4 {
    font-size: 0.9285714286rem;
  }
  .balance-label {
    font-size: 16px;
  }
  .market-depth-body .match-point {
    font-size: 14px;
  }
  .select-container .dropdown-toggle::after {
    background-size: 13px;
  }
  .select-container .dropdown-toggle {
    font-size: 15px;
  }
  .match-odd-table li h2 {
    font-size: 0.9285714285714286rem;
  }
  .plays-bet form {
    margin-top: 0px;
  }
  .bet-numeric button {
    width: calc(25% - 4px);
  }
  .fix-bet-btn button {
    width: calc(25% - 4px);
    font-size: 15px;
    padding: 5px 4px;
  }
  .cross-bet img {
    max-width: 33px;
  }
  .cross-bet {
    height: 150px;
    width: 104px;
  }
  .logo-sec {
    max-width: 106px;
    flex: 0 0 106px;
  }
  .header.before-login {
    padding: 10px 6px;
  }
  .game-card {
    border-radius: 6px;
  }
  .games-inner aside li {
    padding: 7px 7px;
    border-radius: 9px;
    margin-bottom: 8px;
  }
  /* .games-wrapper {
    padding: 3px 1px 3px 0px;
  } */
  .games-inner aside li svg {
    font-size: 27px;
  }
  .games-inner aside {
    max-width: 71px;
    flex: 0 0 85px;
    padding: 4px 6px;
    margin-right: 10px;
  }
  .games-card-inner h2 {
    font-size: 14px;
    border-left: 2px solid #000;
  }
  .games-inner {
    padding: 12px 8px;
  }
}
